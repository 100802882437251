import React from "react"

import Layout from "../../components/layout"
import Hero from "../../components/Hero"
import { PeopleHunt } from "../../components/PeopleHunt"
import { Contact } from "../../components/Contact"
import SEO from "../../components/seo"
import ReferenceContainer from "../../components/ReferenceContainer"

const CustomSolutions = () => {
  return (
    <Layout>
      <SEO title="Custom Solutions" />
      <Hero
        heading={`Custom Solutions`}
        goto="references"
        scrollTitle="Custom Solutions"
      />
      <ReferenceContainer></ReferenceContainer>
      <PeopleHunt />
      <Contact />
    </Layout>
  )
}

export default CustomSolutions
